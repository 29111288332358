import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
import Video from "../components/video"
import JoeLogo from "../../static/images/joeface.png"
import Linkedin from "../../static/images/linkedin.png"
import Behance from "../../static/images/behance.png"
import Email from "../../static/images/email.png"
import CVimage from "../../static/images/cv.png"
import Resume from "../../static/downloads/Joe_Resume_2022.pdf"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Joe The Creative Developer</title>
        <meta name="The Creative Developer" content="Joe's Portfolio" />
      </Helmet>
      <section className={styles.header}>
        <div className={styles.mainText}>
          <img src={JoeLogo} height="250px" width="auto" alt="logo"></img>
          <h3>
            Hi, I'm <span className={styles.joe}>Joe</span>!
          </h3>
          <p className={styles.p}>
            <span className={styles.developer}>Creative Developer</span> with a
            passion for Motion Design and <br></br>
            <span className={styles.developer}>
              Full-Stack Web Development
            </span>{" "}
            based in Mountain View, CA.
          </p>
          <Link className={styles.btn} to="/dev">
            Web Development
          </Link>
          <Link className={styles.btn} to="/design">
            Motion Design
          </Link>
        </div>
        <div className={styles.vid}>
          <Video
            videoSrcURL="https://player.vimeo.com/video/711860327"
            videoTitle="Joe Chung 2022 Demo Reel"
            videoWidth="100%"
            videoHeight="600px"
            videoMinWidth="500px"
          />
        </div>
      </section>
      <div className={styles.mainText}>
        <p>Contact Me:</p>
        <div className={styles.socials}>
          <a
            href="https://www.linkedin.com/in/joechung76/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} height="35px" width="auto" alt="linkedin"></img>
          </a>
          <a
            href="https://www.behance.net/joechung76"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Behance} height="35px" width="auto" alt="behance"></img>
          </a>
          <a
            href="mailto:joechung76@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Email} height="35px" width="auto" alt="email"></img>
          </a>
          <a href={Resume} download>
            <img src={CVimage} height="35px" width="auto" alt="resume"></img>
          </a>
        </div>
      </div>
    </Layout>
  )
}
